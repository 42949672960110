#doors-list .index-table {
  height: calc(100vh - 16rem);
}

#doors-list tr.sub-row {
  background-color: #f3f3f3;
}

#doors-list tr.sub-row:hover {
  background-color: #f9f9f9 !important;
}

@media (max-width: 769px) {
  #doors-list .content-main {
    margin: 0px;
  }

  .table thead th {
    top: 0;
  }
}
