#quote-reports-card .material-icons-outlined {
  color: #56d927;
  opacity: 0.6;
  font-size: 22px; 
}

#quote-reports-card .text {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}
