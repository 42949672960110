#duplicate-door #dropmenu {
  padding: 2rem;
  top: 0;
  left: -28rem;
  width: 32rem;
}

#duplicate-door h1 {
  font-size: 1.6rem;
  margin-bottom: 1.3rem;
  
}

#duplicate-door button {
  margin-left: 2.3rem;
  padding: 1rem 1.8rem;
}
