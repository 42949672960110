@media (max-width: 602px) {
  #usersIndex .table thead th {
    top: 11.85rem;
  }
}

@media (max-width: 375px) {
  #usersIndex .table thead th {
    top: 17.1rem;
  }
}

#usersIndex .table thead th {
    top: 7rem;
}
