#suggestions-wrapper {
  position: absolute;
  width: 80%;
  max-width: 600px;
  height: 300px;
  left: 22px;
  z-index: 999
}

#address-results-list ul {
  position: absolute;
  overflow: scroll;
  height: 300px;
  width: 100%;
  z-index: 999;
}

#address-results-list li {
  background: #f7f7f7;
  cursor: pointer;
}

#address-results-list li:hover {
  background: #f2f2f2;
}

#suggestions-wrapper #close-button {
  color: red;
  cursor: pointer;
  z-index: 2000;
  position: absolute;
  right: -25px;
}

@media (max-width: 768px) {
  #address-fields #suggestions-wrapper {
    top: -98px;
  }
}
