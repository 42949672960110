@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
:root {
    --color-primary: #FF4D4D;
    --color-grey-divider: #9A9A9A;
    --color-white: #ffffff;
    --navbar-icons-color: #BCBCCB;
    --navbar-divider-color: #EBEBF2;
    --navbar-text-color: #4D4F5C;
    --main-background: #F2F4F8;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
html {
    font-size: 62.5%; /* 1rem = 10px */
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
}

p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.material-icons-outlined {
    vertical-align: middle;
    vertical-align: -webkit-middle;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    background: #F5F8FF;
}

/* Sidebar Styling */

#sidebar {
    min-width: 10rem;
    max-width: 10rem;
    color: #fff;
    transition: all 0.3s;
    background: #FF4D4D;
    background: var(--color-primary);
    position: relative;
}

.sidebar__toggler {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0 2rem;
    background: #FF4D4D;
    background: var(--color-primary);
    border-radius: 0px;
}

.close-btn {
    display: none;
}

.sidebar__toggler .icon {
    font-size: 3rem;
    color: #fff;
}

.sidebar-header {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    height: 8rem;
    position: fixed;
    left: 4rem;
}

.sidebar__list {
    margin-top: 10rem;
    position: sticky;
    position: -webkit-sticky;
    top: 10rem;
}

.sidebar__link-icon {
    font-size: 2.6rem;
}

.sidebar__link-icon.icon-center {
    transform: translateX(1.4rem);
}

.sidebar__item {
    opacity: .4;
    position: relative;
}

.sidebar__item.focused {
    opacity: 1;
}

.sidebar__item.focused::after {
    opacity: 1;
}

.sidebar__item::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 0;
    width: 4px;
    height: 3rem;
    background: #fff;
    opacity: 0;
}


.sidebar__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.75rem;
    padding: 0px 3px;
}

.sidebar__link-icon {
    height: 2.5rem;
    margin-bottom: .3rem;
}

.sidebar__link-text {
    font-size: 1.4rem;
    text-align: center;
}


/* Content style */

#content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    overflow-x: hidden;
}

/* Navbar Styling */
.navbar {
    height: 7rem;
    padding: 0 3rem;
    background: #ffffff;
    background: var(--color-white);
    box-shadow: 0px 2px 6px #0000000A;
    position: fixed;
    top: 0;
    left: 10rem;
    right: 0;
    z-index: 1000;
}

.navbar-big {
    height: 12rem;
}

.navbar-heading {
    font-size: 2rem;
    color: #FF4D4D;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.navbar-heading a .material-icons-outlined {
    font-size: 3rem;
    padding-right: 1rem;
    position: relative;
}

.dropdown-icon span {
    transform: translateY(-.5rem);
    color: #BCBCCB;
}

.navigation {
    display: flex;
    list-style: none;
    align-items: center;
    margin-bottom: 0;
}

.navigation-bottom {
    position: absolute;
    bottom: 0;
    left: 10rem;
    right: 0;
    margin-top: 2rem;
}

.navigation .nav-item {
    padding: 0 .5rem;
}

.navigation-bottom .nav-item {
    padding: 0 3rem;
}

.nav-item .nav-link:link,
.nav-item .nav-link:visited {
    font-size: 1.6rem;
    font-weight: 500;
    color: #FF4D4D;
    padding: .5rem;
    padding-bottom: 1rem;
    opacity: 0.5;
    transition: all .3s ease;
    border-bottom: 3px solid transparent;
}

.nav-item .nav-link.active {
    opacity: 1;
    border-bottom: 3px solid #FF4D4D;
}

.nav-tabs .nav-link {
    border: none;
}

.nav-tabs .nav-item {
    margin-bottom: 0;
}
.nav-btn:link,
.nav-btn:visited {
    color: #FF4D4D;
    color: var(--color-primary);
    padding: .6rem 1rem;
    border-radius: 5px;
    transition: all .3s ease;
    min-width: 6rem;
    display: inline-block;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
}

.nav-btn .material-icons-outlined{
    padding-right: .5rem;
    font-size: 2rem;
}

.btn-border:link,
.btn-border:visited {
    border: 1px solid #FF4D4D;
    border: 1px solid var(--color-primary);
}

.btn-border:hover {
    background: #FF4D4D;
    background: var(--color-primary);
    color: #fff;
}

.btn-fill:link,
.btn-fill:visited {
    background: #FF4D4D;
    background: var(--color-primary);
    color: #fff;
    border: 1px solid #FF4D4D;
    border: 1px solid var(--color-primary);
}

.btn-fill:hover {
    background: #fff;
    color: #FF4D4D;
    color: var(--color-primary);
    border: 1px solid #FF4D4D;
    border: 1px solid var(--color-primary);
}

.divider {
    border-right: 1px solid #EBEBF2;
    border-right: 1px solid var(--navbar-divider-color);
    position: relative;
}

.dot {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    border: 1px solid #fff;
    background: #FFC06A;
    position: absolute;
    top: 4px;
    right: 1px;
}

.dropdown-icon {
    font-size: 2rem;
    color: #BCBCCB;
    color: var(--navbar-icons-color);
}

.dropdown-btn {
    color: #4D4F5C;
    color: var(--navbar-text-color);
}

.dropdown-menu {
    font-size: 1.4rem;
    left: -7rem;
}

.navbar-profile-img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.card {
    margin: 2rem;
    background: #fff;
    box-shadow: 0px 2px 6px #0000000A;
    border: none;
    border-radius: 4px;
    position: relative;
}

.card-scrollable {
    height: calc(100vh - 14rem);
    overflow: hidden;
    position: relative;
}

.card-scrollable .card-body {
    height: 100%;
}

.card-header {
    padding: 2rem 3rem;
    background: #fff;
    border-bottom: 1px solid #E9ECEF;
}

.card-heading {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #6C757D;
}

.card-body {
    padding: 3rem;
    padding-bottom: 6rem;
    position: relative;
}


.card-body.text-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-body.text-card.pad-10rem {
    padding: 10rem 2rem;
}

.card-body.text-card.pad-5rem {
    padding: 5rem 2rem;
}

.card-body.text-card h2,
.card-body.text-card h1 {
    color: #6C757D;
    text-align: center;
    margin-bottom: 0;
    font-weight: 500;
}

/* Main Content */
.content-main {
    margin: 2rem;
    background: #fff;
    box-shadow: 0px 2px 6px #0000000A;
    height: calc(100vh - 11rem);
    overflow: scroll;
    position: relative;
    border-radius: 4px;
}

.content-main-small {
    height: calc(100vh - 16rem);
}

.content-main .table-footer-label {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 1.4rem 4rem;
    display: block;
    color: #6C757D;
    font-weight: 500;
}


.content-main::-webkit-scrollbar {
    display: none;
}

.content-main {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.card-top {
    margin-top: 9rem;
}

.card-top-big {
    margin-top: 14rem;
}

.content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
    width: 100%;
    padding: 2.7rem 2rem 2rem 2rem;
}

.content-body {
    padding: 0 2rem 0rem 2rem;
}

.content-title {
    font-size: 1.6rem;
    color: #6C757D;
    text-transform: uppercase;
}

.content-search {
    display: flex;
}

.input-group {
    margin-right: 2rem;
}

.search-input {
    position: relative;
    padding: 1rem 1.5rem;
    padding-left: 4.5rem;
    border-radius: 5rem;
    border: 1px solid #EBEBF2;
    border: 1px solid var(--navbar-divider-color);
    font-family: inherit;
    color: #9A9A9A;
    color: var(--color-grey-divider);
    width: 100%;
    outline: none;
    transition: all .2s ease;
}

.search-input::-webkit-input-placeholder {
    color: #999; 
    -webkit-transition: all .2s ease; 
    transition: all .2s ease;
}

.search-input:-ms-input-placeholder {
    color: #999; 
    -ms-transition: all .2s ease; 
    transition: all .2s ease;
}

.search-input::-ms-input-placeholder {
    color: #999; 
    -ms-transition: all .2s ease; 
    transition: all .2s ease;
}

.search-input::placeholder {
    color: #999; 
    transition: all .2s ease;
}

.search-icon {
  cursor: pointer;
    position: absolute;
    top: 1.2rem;
    left: 1.5rem;
    color: #BCBCCB;
    font-size: 2.3rem;
    transition: all .2s ease;
}
 
.search-icon:hover {
  color: #72ACFD;
}

/* Tagging */

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
}

.react-tagsinput--focused {
    border: 1px solid #72ACFD;
}

.react-tagsinput-tag {
  border-radius: 2px;
  border: 1px solid ##0046b8;
  background-color: #cde69c;
  color: #638421;
  display: inline-block;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-right: 4px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
}

.btn:link,
.btn:visited {
    padding: .8rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all .15s ease;
    font-size: 1.4rem;
}

.btn:focus,
.btn:active {
    outline: none;
    box-shadow: none;
}

.btn-rounded {
    border-radius: 5rem;
}

.btn-shadow {
    box-shadow: 1px 1px 10px #00000029;
}

.btn-red:link,
.btn-red:visited {
    background: #FF4D4D;
    color: #fff;
    border: 1.5px solid #FF4D4D;
}

.btn-white:link,
.btn-white:visited {
    background: #fff;
    color: #FF4D4D;
    border: 1.5px solid #fff;
}

.btn-bordered:link,
.btn-bordered:visited {
    background: none;
    border: 1.5px solid #FF4D4D;
    color: #FF4D4D;
    border-radius: 4px;
}


.btn-red:hover,
.btn-white:hover,
.btn-bordered:hover {
    transform: translateY(-3px);
    box-shadow: 1px 1px 10px #00000029;
}

.btn-small {
    width: 13rem;
}

.btn-medium {
    width: 18rem;
}

.btn-big {
    width: 22rem;
}

#endCustomer-table-header .btn {
  width: 28rem;
}

.table {
    width: 100%;
    border-collapse: unset;
    border-spacing: 0;
}

.table thead th {
    border-top: none;
    border-bottom: none;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 9rem;
    z-index: 50;
    cursor: pointer;
}

.table th.required-date {
  min-width: 140px;
}

.loading-content {
  opacity: 0.4;
}

.content-body #loading-spinner {
  width: 60px;
  height: auto;
  margin-top: 80px;
  opacity: 1;
}

.center-overlay {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-top: -50px; 
  margin-left: -50px;
  z-index: 9999;
}

.card-scrollable .table thead th {
    padding-top: 2rem;
    top: 0;
}

.card-scrollable .card-body {
    padding-top: 0;
    overflow: scroll;
}

.card-scrollable .card-body::-webkit-scrollbar {
    display: none;
}

.card-scrollable .card-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.translate-3px {
    display: inline-block;
    transform: translateY(-3px);
    transition: all .3s ease;
}

.table thead th .desc {
    transform: translateY(3px) rotate(180deg);
}

.table th, .table td,
table.dataTable tbody th, table.dataTable tbody td {
    padding: 1.8rem 2.5rem;
    color: #6C757D;
    vertical-align: middle;
}

.table thead th, .table tbody td {
    margin-left: -5px;
}

.table thead th {
    border-top: 1px solid #eef2f7;
    border-bottom: 2px solid #eef2f7;
}

.table tbody tr:first-child td {
    border-top-color: transparent;
}

.table tbody td {
    border-top: 1px solid #eef2f7;
    position: relative;
}

.table tbody td #icon-btn span {
    display: none;
}

.table tbody td #cancelBtn {
    color: #FF4D4D;
    z-index: 1100;
}

.table tbody td #icon-btn span.show {
    display: inline-block;
}

.mobile-action-menu {
    position: absolute;
    top: 50%; 
    left: 50%;
}

.table td .icon-grey {
    color: #BCBCCB;
    cursor: pointer;
    font-size: 2rem;
}

.table td .icon-light {
    color: #BCBCCB;
}

.table td .icon-plus {
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
}

.table td .color-grey {
    color: #BCBCCB;
}

.table td .color-green {
    background: #4CAF50;
}

.collapse-btn span {
    display: none;
}

.collapse-btn span.show {
    display: inline-block;
}

.table tbody td .material-icons-outlined,
.table tbody td .material-icons {
    cursor: pointer;
}

.table-hover tbody tr:hover {
    background: #f8f9fa;
}

.table-column-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.table-column-btn {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    font-size: 1.2rem;
}

.table-column-btn.btn-active {
    background: #5CB85C;
    color: #fff;
}

.table-column-btn.btn-inactive {
    background: #FF4D4D;
    color: #fff;
}

.table-column-btn.btn-pending {
    background: #FFC06A;
    color: #fff;
}

.table td .dropdown-menu {
    top: 12px;
    left: -15rem;
    width: 16rem;
    box-shadow: 3px 3px 10px #00000029;
    border-radius: 4px;
    border: none;
}

.table td .dropdown-menu .dropdown-item {
    color: #616161;
    font-size: 1.4rem;
    padding-left: 2rem;
}

.table td .dropdown-menu .dropdown-item:hover {
    background: #F5F8FF;
}

.table td .dropdown span {
    position: absolute;
    top: -1.2rem;
    right: 2rem;
    z-index: 10000;
    color: #BCBCCB;
    display: none;
}

.table td .dropdown span:last-child {
    color: #FF4D4D;
}

.table td .dropdown span.activeIcon {
    display: inline-block;
}

.dropdown-menu a {
    padding: 1rem;
}

.dropdown-item {
  cursor: pointer;
}

.search-menu {
  left: 0px;
}

/* Dropdown Menu in Search */

.table tbody td .table-action #icon-btn {
    display: block;
    position: absolute;
    top: 10rem;
}

.table .hidden-row {
    display: none
}

.modal-header {
    border-bottom: 0;
}

.modal-header .close {
    font-size: 2rem;
}

.modal-title {
    color: #495057;
    font-size: 2rem;
}

.modal-body {
    color: #6C757D;
    font-size: 1.4rem;
    margin-bottom: 2rem;
}

.modal-footer {
    border-top: 0;
    padding-bottom: 1rem;
}

.modal-footer .btn{
    font-size: 1.4rem;
    padding: .5rem 2rem;
    border: none;
    border-radius: 4px;
}

.modal-footer .btn-secondary {
    background: #E0E1E2;
    color: #00000099;
}

.modal-footer .btn-primary {
    background: #FF4D4D;
    color: #fff;
}


.v-sm-visible {
    visibility: visible;
    height: auto;
    width: auto;
}

.scrollable {
    display: block;
    height: 80%;
    overflow-y: scroll;
    width: 100%;
    position: relative;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.dataTables_scrollBody::-webkit-scrollbar {
    display: none
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable, .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
    scrollbar-width: none;
}

/* Form and Card Styling */
.div-label {
    color: #6C757D;
    font-weight: 500;
    margin-top: 4rem;
    margin-bottom: .5rem;
}

.label-div {
    border: 1px solid #E9ECEF;
    padding: 1rem;
}

.form-group label {
    color: #6C757D;
    font-weight: 500;
}

.form-control {
    width: 100%;
    display: block;
    font-size: 1.4rem;
    padding: 2rem 1.5rem;
    outline: none;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #E9ECEF;
}

.form-row {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
}

.form-row>.col, .form-row>[class*=col-] {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.select-box {
    position: relative;
}

.select-box select {
    padding: 1rem;
    width: 100%;
    min-width: 10rem;
    font-size: 1.4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    border: 1px solid #E9ECEF;
    outline: none;
    border-radius: 2px;
    color: #999;
    background: transparent;
    border-radius: 4px;
}

.select-box select option {
    color: #333;
}

.select-box select:focus {
    border: 1px solid #72ACFD;
}

.select-box::after {
    content: "";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    margin-top: -4px;
    border-top: 5px solid #6C757D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

select.form-control:not([size]):not([multiple]) {
    height: 4rem;
    padding: 1rem ;
}

select.form-control::-webkit-input-placeholder {
    color: #E9ECEF;
}

select.form-control:-ms-input-placeholder {
    color: #E9ECEF;
}

select.form-control::-ms-input-placeholder {
    color: #E9ECEF;
}

select.form-control::placeholder {
    color: #E9ECEF;
}

.form-control {
    box-shadow: none;
}

.form-control:focus,
.form-control:active {
    box-shadow: none;
    border: 1px solid #72ACFD;
}

.form-control.invalid {
  border: 1px solid #FF4D4D;
  border: 1px solid var(--color-primary);
}

.dropdownInput-wrapper [class*="-control"] {
  border-color: #E9ECEF;
}

.dropdownInput-wrapper [class*="-control"]:hover {
  border-color: #E9ECEF;
}

.invalid-input [class*="-control"] {
  border: 1px solid #FF4D4D;
  border: 1px solid var(--color-primary);
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
}

span.input-error {
  padding-left: 5px;
  color: red;
}

.form-control::-webkit-input-placeholder {
    color: #E9ECEF;
}

.form-control:-ms-input-placeholder {
    color: #E9ECEF;
}

.form-control::-ms-input-placeholder {
    color: #E9ECEF;
}

.form-control::placeholder {
    color: #E9ECEF;
}

.dropzone {
    border: 2px dashed #E9ECEF;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    min-height: 23rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropzone .dz-message {
    text-align: center;
}

.dropzone .dz-message .icon,
.dropzone .dz-message h3 {
    color: #6C757D;
    font-size: 1.4rem;
}

.dropzone .dz-message .icon {
    font-size: 8rem;
    margin-bottom: 1.5rem;
}

.files-container {
    border: 2px solid #E9ECEF;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    min-height: 23rem;
    padding: 2rem;
}

.files-container .label {
    color: #6C757D;
    font-weight: 500;
}

.files-container .files-list {
    list-style: none;
    margin: 2rem;
}

.files-list .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.files-list .file {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
}

.files-list .file-icon {
    display: inline-block;
    color: #FF4D4D;
    padding-right: 5px;
}

.files-list .file-name {
    color: #6C757D;
}

.files-list .file-name:hover {
    cursor: pointer;
    color: black;
    text-decoration: underline;
}

.list-item .delete-icon {
    color:  #BCBCCB;
}

.doors-radio-input,
.doors-rebate-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E9ECEF;
    padding: 2rem;
}

.doors-rebate-input {
    padding: 1.5rem;
}

.doors-radio-input .label,
.doors-rebate-input .label,
.form-group-bordered .label {
    font-weight: 500;
    color: #6C757D;
}

.doors-radio-input .radio-input {
    display: flex;
    align-items: center;
}

.doors-radio-input .radio-input .door-icon {
    padding-right: 1rem;
}

.doors-radio-input .radio-input .door-input {
    display: flex;
    align-items: center;
}

.doors-radio-input .radio-input .label-text {
    display: inline-block;
    padding-left: 5px;
    margin-bottom: 0;
}

.doors-radio-input .label .light {
    font-weight: 400;
}

#date-picker-input [role="right-icon"] {
  z-index: 0;
}

.form-group-bordered {
    border: 1.5px solid #E9ECEF;
    padding: 2rem 3rem;
    border-radius: 4px;
    margin-top: 2rem;
}


.form-icons-group {
    display: flex;
    justify-content: space-evenly;
    transform: translateY(1rem);
}

.form-icons-group span {
    color: #BCBCCB;
}

.sidebar__toggler {
    display: none;
}

.nav-tabs {
    border: 0px;
}

.hidden {
  display: none;
}

/* 
@media (max-width: 1024px) and (max-height: 1366px) {
    .scrollable {
        height: 100%;
    }
}

@media (max-width: 768px) and (max-height: 1024px) {
    .scrollable {
        height: 93%;
    }
}

@media (max-height: 830px) {
    .scrollable {
        height: 88%;
    }
}

@media (max-height: 670px) {
    .scrollable {
        height: 85%;
    }
}

@media (max-height: 570px) {
    .scrollable {
        height: 73%;
    }
} */

/* components */
.alert {
    padding: 2rem 2.5rem;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
}

.alert h2,
.alert p {
    color: #fff;
}

.alert.alert-small {
    max-width: 50%;
}

.alert.alert-small h2{
    margin-bottom: 0;
    text-align: center
}

.alert h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.alert p {
    font-weight: 400;
}

.alert .close {
    font-size: 2.5rem;
    color: #fff;
    opacity: 1;
}

.alert-warning {
    background: #FEC96C;
}

.alert-success {
    background: #4CAF50;
}

.alert-info {
    background: #72ACFD;
}

.alert-danger {
    background: #FD4D4D;
}

/* dropdown list in table */
.table-menu {
    position: relative;
    max-width: 18rem;
}

.table-menu .dropdown-btn span {
    position: absolute;
    top: -1.5rem; 
    right: -1.5rem;
    color: #fff;
    font-size: 3.5rem;
    background: #FF4D4D;
    border-radius: 50%;
}


.table-menu .dropdown-list {
    background: #fff;
    box-shadow: 3px 3px 10px #00000029;
    border-radius: 4px;
}

.table-menu .dropdown-list a {
    padding: 1.5rem;
    transition: all .3s;
    color: #616161;
}

.table-menu .dropdown-list a:hover {
    background: #F5F8FF;
}


/* Box or modals */
.box {
    background: #fff;
    max-width: 50rem;
    box-shadow: 0px 3px 20px #00000033;
    border-radius: 4px;
    padding: 2rem;
}

.box-delete {
    max-width: 60rem;
}

.box-header {
    margin-bottom: 1.5rem;
}

.box-title {
    font-size: 1.5rem;
    color: #6C757D;
}

.box-description {
    font-size: 1.4rem;
    font-weight: 400;
    color: #6C757D;
    margin-bottom: 1rem;
}

.box-body .form-row {
    align-items: flex-end;
}

.box-body .form-control {
    text-align: center;
}

.box-body .form-row .btn {
    padding: .75rem 2rem;
    box-shadow: 4px;
}

.box-footer {
    border-top: 0;
    padding-top: 1rem;
}

.box-footer .btn{
    font-size: 1.4rem;
    padding: .75rem 2rem;
    border: none;
    border-radius: 4px;
    display: inline-block;
}

.box-footer .btn-secondary {
    background: #E0E1E2;
    color: #00000099;
}

.box-footer .btn-primary {
    background: #FF4D4D;
    color: #fff;
}


.c-icon-group .icon {
    padding: .8rem;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #0000000A;
    display: inline-block;
    align-items: center;
    justify-content: center;
}

.c-icon-group .icon .material-icons-outlined {
    font-size: 2.5rem;
    opacity: 1;
}

.c-icon-group .icon-edit {
    background: #F8EBE3;
    color: #F2711C;
}

.c-icon-group .icon-copy {
    background: #F1F6FE;
    color: #72ACFD;
}

.c-icon-group .icon-delete {
    background: #FAEBE5;
    color: #DB2828;
}


@media (max-width: 1112px) {
    html {
        font-size: 56.25%; /* 1rem = 9px */
    }

    #sidebar {
        min-width: 25rem;
        max-width: 25rem;
        margin-left: -25rem;
        position: fixed;
        height: 100vh;
        z-index: 1100;
    }

    .navbar {
        left: 0;
    }

    #sidebar.active .sidebar-header {
        display: block;
    }

    .sidebar-header {
        display: none;
    }
    
    .close-btn {
        display: inline-block;
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 2.5rem;
    }
    
    .sidebar__item {
        margin-top: 3.5rem;
    }
        
    .sidebar__item::after {
        top: 0;
    }

    .sidebar__link-icon.icon-center {
        transform: translateX(0);
    }

    .sidebar__link {
        flex-direction: row;
        padding-left: 4rem;
        font-size: 1.5rem;
    }
    .sidebar__link .sidebar__link-icon {
        margin-right: 1rem;
        width: 3rem;
        height: 3rem;
    }
    
    nav.active {
        transform: translateX(25rem);
    }
    .sidebar__toggler {
        display: block;
    }
    .navbar-heading {
        margin-left: 5rem;
    }
    .table th, .table td {
        padding: 1.6rem 1rem;
    }
    .table thead th {
        top: 9.3rem;
    }
}

/* Media Quries */
@media (max-width: 992px) {
    .form-row>.col, .form-row>[class*=col-] {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .table th, .table td {
        padding: 1.5rem 1rem;
        font-size: 1.6rem
    }
}

@media (max-width: 769px) {
    .heading-small {
        font-size: 2rem;
        word-wrap: normal;
    }

    .navigation-bottom {
        left: 3rem;
        overflow-x: scroll;
        width: 100vw;
    }
    
    .navigation-bottom .nav-item {
        padding: 0 2rem;
    }

    .form-row>.col, .form-row>[class*=col-] {
        padding-right: .8rem;
        padding-left: .8rem;
    }

    .form-row {
        margin-top: 0;
    }

    .form-group {
        margin-bottom: 3rem;
    }

    .table th, .table td {
        padding: 1.5rem .5rem;
    }

    .button-group.card-fixed-nav {
        margin-top: 12rem;
        padding: 2rem 1rem;
    }
    .icon-btn {
        background-color: #FF4D4D;
        border: none;
        color: white;
        padding: 12px 14px;
        font-size: 14;
        font-weight: 300;
        cursor: pointer;
        border-radius: 4px;
      }
      
      
      /* Darker background on mouse-over */
      .icon-btn:hover {
        background-color: #ff3333;
      }
    .card {
        margin: 9rem 1rem 1rem 1rem;
    }

    .card-top-big {
        margin: 0 1rem 1rem 1rem;
    }

    .content-main.card-top-big {    
        margin: 0rem 1rem 1rem 1rem;
    }

    .card-header {
        padding: 1.5rem 2rem
    }

    .card-body {
        padding: 1.5rem 2rem;
    }

    .card-body.text-card h2, .card-body.text-card h1 {
        margin-bottom: 2rem;
    }

    .card-body.text-card h2 {
        text-align: left;
    }
    
    .card-body.text-card h1 {
        text-align: right;
    }
}

@media (max-width: 602px) {
    
    .table thead th {
        top: 13.85rem;
    }

    .content-body-small .table thead th {
        top: 8.9rem;
    }
}

@media (max-width: 576px) {

    .mx-sm-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .container-fluid {
        padding-right: .5rem;
    }

    .sidebar__toggler {
        background: none;
    }

    .sidebar__toggler .icon {
        color: #FF4D4D;
        color: var(--color-primary);
    }

    .navbar {
        padding: 0 1rem;
        box-shadow: none;
    }

    .navigation {
        align-self: flex-end;
    }

    .nav-item .dropdown-icon {
        padding-right: 1rem;
    }

    .navbar-profile-img {
        margin-left: 1rem;
    }

    .heading-big {
        margin-bottom: .5rem;
        font-size: 3rem;
        display: block;
    }
    
    .navigation-bottom {
        left: 0;
        overflow-x: scroll;
    }

    .nav {
        flex-wrap: nowrap;
    }
    
    .navigation-bottom .nav-item {
        padding: 0 1.5rem;
    }

    .navigation-bottom .nav-item .nav-link {
        white-space: nowrap;
    }

    .content-main {
        margin: 7rem 0 0 0;
        height: calc(100vh - 7rem);
    }

    .content-main .table-footer-label {
        padding: 1.5rem 2rem;
    }

    .content-search {
        margin-left: 0;
    }

    .form-row>.col, .form-row>[class*=col-] {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .label-div {
        padding: 2rem;
    }

    .card {
        margin: 0 1rem 1rem 1rem;
    }

    .card-header {
        padding: 1rem
    }

    .card-header-mobile {
        align-items: center;
    }

    .card-header-mobile .form-icons-group {
        margin-bottom: 1.5rem;
    }
    
    .card-header-mobile .form-icons-group a {
        margin-right: 2rem;
    }

    .card-body {
        padding: 1rem;
    }

    .button-group.button-group.card-fixed-nav {
        width: 100%;
        margin: 7rem 0px 0px 0px;
        padding: 2rem 1rem;
        box-sizing: border-box;
        display: flex;
    }

    .button-group.button-group.nav-big {
        margin-top: 12rem;
    }
    
    .doors-radio-input {
        flex-direction: column;
        margin-bottom: 5rem;
    }

    .doors-radio-input .label {
        margin-bottom: 2.5rem;
    }

    .doors-radio-input .radio-input:not(:last-child) {
        margin-bottom: 2rem;
    }

    .doors-rebate-input,
    .doors-radio-input {
        margin-top: 1rem;
    }

    .doors-rebate-input .label,
    .doors-radio-input .label {
        margin-top: 2.5rem;
        position: absolute;
        top: -4rem;
        left: 5px;
    }

    .form-group-bordered {
        padding: 0;
    }

    .table .hidden-row {
        display: none;
        width: 100%;
    }

    .table .hidden-row.show {
        display: inline-block;
        width: 100%;
        background: #F1F3FA;
    }

    .card.add-job .card-header {
        margin-bottom: 0;
    }

    .card-scrollable .table thead th {
        top: -1px;
    }

    /*.btn-round {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        font-size: 2.5rem;
        box-shadow: 0 .5rem 2rem rgba(0,0,0,.15);
        border-radius: 50%;
        margin-right: 1rem;
    }

    .btn-white {
        background: #fff;
        color: var(--color-primary);
    }

    .btn-red {
        background: #FF4D4D;
        color: #fff;
    } */

    .v-sm-visible {
        visibility: hidden;
        width: 0;
        height: 0;
        display: none;
    }

    .width-80 {
        width: 90%;
    }
  }

@media (max-width: 375px) {
    .content-search {
        flex-wrap: wrap;
    }

    .table thead th {
        top: 19.1rem;
    }

    .content-body-small .table thead th {
        top: 14.6rem;
    }

    .content-search .search-input {
        margin-bottom: 1rem;
    }

    .add-btn:link, .add-btn:visited {
        margin-top: 1rem;
    }

    .btn-medium {
        width: 12rem;
    }

    .btn-big {
        width: 15rem;
    }
}

#sell-price-input {
  width: 70px;
  height: 25px;
  border: 1px solid grey;
  opacity: 0.9;
  padding-left: 3px;
  margin: 0;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

#sell-price.edited {
  color: green;
}

#sell-price span.material-icons-outlined {
  color: red;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
input[type="number"] {
   -moz-appearance: textfield;
}

#editable-input input {
}

#editable-input #clear-button {
  position: absolute;
  right: 3%;
  top: 2%;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

#editable-input #clear-button {
  position: absolute;
  right: 2%;
  top: 35%;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

@media (min-width: 770px) {
  #editable-input #clear-button {
    top: 42%;
  }
}

@media (min-width: 1112px) {
  #editable-input #clear-button {
    top: 46%;
  }
}

@media (min-width: 1300px) {
  #editable-input #clear-button {
    right: 1.5%;
  }
}

@media (max-width: 602px) {
  #usersIndex .table thead th {
    top: 11.85rem;
  }
}

@media (max-width: 375px) {
  #usersIndex .table thead th {
    top: 17.1rem;
  }
}

#usersIndex .table thead th {
    top: 7rem;
}

#suggestions-wrapper {
  position: absolute;
  width: 80%;
  max-width: 600px;
  height: 300px;
  left: 22px;
  z-index: 999
}

#address-results-list ul {
  position: absolute;
  overflow: scroll;
  height: 300px;
  width: 100%;
  z-index: 999;
}

#address-results-list li {
  background: #f7f7f7;
  cursor: pointer;
}

#address-results-list li:hover {
  background: #f2f2f2;
}

#suggestions-wrapper #close-button {
  color: red;
  cursor: pointer;
  z-index: 2000;
  position: absolute;
  right: -25px;
}

@media (max-width: 768px) {
  #address-fields #suggestions-wrapper {
    top: -98px;
  }
}

.react-datepicker {
  font-size: 1em;
}
.react-datepicker-wrapper {
  flex: 1 1 auto !important
}
.react-datepicker__header {
  padding-top: 0.5em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1.2em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

#date-picker-input .datepicker-icon {
  font-size: 18px;
  margin-top: 4px;
}

#date-picker-input .btn-outline-secondary {
  border-color: rgb(233, 236, 239);
  cursor: inherit;
}

#edit-job .index-table {
  margin-top: 0px;
}

#edit-job .table thead th {
  top: 0rem;
}

@media (max-width: 769px) {
  #edit-job .card {
    margin: 1rem 1rem 1rem 1rem;
  }
}



#doors-list .index-table {
  height: calc(100vh - 16rem);
}

#doors-list tr.sub-row {
  background-color: #f3f3f3;
}

#doors-list tr.sub-row:hover {
  background-color: #f9f9f9 !important;
}

@media (max-width: 769px) {
  #doors-list .content-main {
    margin: 0px;
  }

  .table thead th {
    top: 0;
  }
}

#duplicate-door #dropmenu {
  padding: 2rem;
  top: 0;
  left: -28rem;
  width: 32rem;
}

#duplicate-door h1 {
  font-size: 1.6rem;
  margin-bottom: 1.3rem;
  
}

#duplicate-door button {
  margin-left: 2.3rem;
  padding: 1rem 1.8rem;
}

#quote-reports-card .material-icons-outlined {
  color: #56d927;
  opacity: 0.6;
  font-size: 22px; 
}

#quote-reports-card .text {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

