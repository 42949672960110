#editable-input input {
}

#editable-input #clear-button {
  position: absolute;
  right: 3%;
  top: 2%;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

#editable-input #clear-button {
  position: absolute;
  right: 2%;
  top: 35%;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

@media (min-width: 770px) {
  #editable-input #clear-button {
    top: 42%;
  }
}

@media (min-width: 1112px) {
  #editable-input #clear-button {
    top: 46%;
  }
}

@media (min-width: 1300px) {
  #editable-input #clear-button {
    right: 1.5%;
  }
}
