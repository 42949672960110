#edit-job .index-table {
  margin-top: 0px;
}

#edit-job .table thead th {
  top: 0rem;
}

@media (max-width: 769px) {
  #edit-job .card {
    margin: 1rem 1rem 1rem 1rem;
  }
}
