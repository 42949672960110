#sell-price-input {
  width: 70px;
  height: 25px;
  border: 1px solid grey;
  opacity: 0.9;
  padding-left: 3px;
  margin: 0;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

#sell-price.edited {
  color: green;
}

#sell-price span.material-icons-outlined {
  color: red;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
input[type="number"] {
   -moz-appearance: textfield;
}
